// ** Redux Imports
import { combineReducers } from "redux"

// ** Reducers Imports
import auth from "./auth"
import navbar from "./navbar"
import layout from "./layout"
import chat from './chat'
import notification from "./notification"
import order from "@src/views/Pedido/store/reducer"

const rootReducer = combineReducers({
  auth,
  navbar,
  layout,
  chat,
  notification,
  order
})

export default rootReducer
