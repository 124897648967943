import React, { Fragment } from "react"

import NotificationNewOrdem from "./new-ordem"
import NotificationNewOrdemSupplies from "./new-ordem-supplies"

import { useDispatch, useSelector } from "react-redux"
import { removeOrder, removeOrderSupplies } from "../../../redux/actions/notification"

const Notification = () => {
  const dispatch = useDispatch()
  const store = useSelector(state => state.notification)

  return (
    <Fragment>
      {store.orders.map((item) => {
        return (
          <NotificationNewOrdem
            key={item.id}
            data={item}
            dispatch={dispatch}
            remove={() => dispatch(removeOrder(item))}
          />
        )
      })}

      {store.ordersSupplies.map((item) => {
        return (
          <NotificationNewOrdemSupplies
            key={item.id}
            data={item}
            dispatch={dispatch}
            remove={() => dispatch(removeOrderSupplies(item))}
          />
        )
      })}
    </Fragment>
  )
}

export default Notification
