const initialState = {
  chats: [],
  selectedUser: {}
}

const chatReducer = (state = initialState, action) => {
  switch (action.type) {
    case 'GET_CHATS':
      return { ...state, chats: action.data }
    case 'CREATE_CHAT':
      return { ...state }
    case 'SELECT_CHAT':
      return { ...state, selectedUser: { chatRoomId: action.chatRoomId }}
    case 'SEND_MSG':
      return { ...state }
    case 'REMOVE_MSG':
      return { ...state }
    default:
      return state
  }
}

export default chatReducer
