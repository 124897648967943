const initialState = {
  orders: [],
  ordersSupplies: []
}

const NotificationReducer = (state = initialState, action) => {
  switch (action.type) {
    case 'ADD_NOTIFICATION_ORDER':
      state.orders.push(action.order)
      return { ...state }
    case 'REMOVE_NOTIFICATION_ORDER':
      state.orders.splice(state.orders.findIndex(i => i === action.order), 1)
      return { ...state }

    case 'ADD_NOTIFICATION_ORDER_OF_SUPPLIES':
      state.ordersSupplies.push(action.data)
      return { ...state }
    case 'REMOVE_NOTIFICATION_ORDER_OF_SUPPLIES':
      state.ordersSupplies.splice(state.ordersSupplies.findIndex(i => i === action.data), 1)
      return { ...state }
    default:
      return state
  }
}

export default NotificationReducer
