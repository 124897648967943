import Toast from '@src/utility/components/toast'
import axios from 'axios'
import { getUserData } from '@src/utility/Utils'

import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'

const MySwal = withReactContent(Swal)

const BASE_URL = process.env.REACT_APP_API_URL_MAIN

// ** GET Orders Immediate
export const getOrdersImmediate = (params) => {
  return (dispatch) => {
    return axios.post(`${BASE_URL}/pedido/filtro`, params).then((res) => {
      dispatch({
        type: 'GET_ORDERS',
        data: res.data,
        type_order: 'immediate',
        params
      })
    })
  }
}

// ** GET Orders Schedule
export const getOrdersSchedule = (params) => {
  return (dispatch) => {
    return axios.post(`${BASE_URL}/pedido/filtro-agendamento`, params).then((res) => {
      dispatch({
        type: 'GET_ORDERS',
        data: res.data,
        type_order: 'schedule',
        params
      })
    })
  }
}

// ** GET order
export const getOrder = (orderId) => {
  return (dispatch) => {
    return axios.get(`${BASE_URL}/pedido/${orderId}`).then((res) => {
      dispatch({
        type: 'GET_ORDER',
        data: res.data
      })
    })
  }
}

function _handleUpdateState(dispatch, state) {
  if (state().order.type_order === 'immediate') {
    dispatch(getOrdersImmediate(state().order.params))
  } else {
    dispatch(getOrdersSchedule(state().order.params))
  }
}

//** Add new order to list */
export const getAllOrders = () => {
  return (dispatch, getState) => {
    dispatch({ type: 'GET_ALL_ORDERS' })
    _handleUpdateState(dispatch, getState)
  }
}

export const addProduct = (orderId, productEstablishment, amount) => {
  return (dispatch) => {
    return axios
      .post(`${BASE_URL}/pedido/adicionar-item`, {
        id_pedido: orderId,
        id_produto_estabelecimento: productEstablishment.id,
        id_preco: productEstablishment.precos[0].id,
        quantidade: amount
      })
      .then((_) => {
        Toast({
          type: 'success',
          title: 'Alteração do Pedido',
          message: 'Produto adicionado com sucesso, aguarde a confirmação do cliente.'
        })

        dispatch({ type: 'ADD_PRODUCT' })
        dispatch(getOrder(orderId))
      })
      .catch((_) => Toast({
          type: 'error',
          title: 'Error',
          message: 'Ops! por algum motivo não conseguimos adicionar o produto ao pedido.'
        })
      )
  }
}

export const alterProduct = (item) => {
  return (dispatch) => {
    return axios
      .post(`${BASE_URL}/pedido/atualizar-item`, {...item})
      .then((_) => {
        Toast({
          type: 'success',
          title: 'Alteração do Pedido',
          message: 'Item do pedido atualizado com sucesso.'
        })

        dispatch({ type: 'UPDATE_PRODUCT' })
        dispatch(getOrder(item.id_pedido))
      })
      .catch((err) => {
        if (err.response.data) {
          Toast({
            type: 'error',
            title: 'Error',
            message: err.response.data.mensagem
          })
        } else {
          Toast({
            type: 'error',
            title: 'Error',
            message: 'Ocorreu um error ao tentar atualizar produto'
          })
        }
      }
    )
  }
}

//** PUT accept order */
export const acceptOrder = (orderId) => {
  return (dispatch, getState) => {
    dispatch({
      type: 'GET_ORDER',
      data: { ...getState().order.orderPreview, isLoading: true }
    })

    return axios
      .put(`${BASE_URL}/pedido/${orderId}/separar`)
      .then(
        (_) => {
          if (getState().order.orderPreview) {
            dispatch({
              type: 'GET_ORDER',
              data: { ...getState().order.orderPreview, id_status: 2 }
            })
          }

          _handleUpdateState(dispatch, getState)
        },
        (_) => {
          Toast({
            type: 'warning',
            title: 'Atenção',
            message: 'Status de pedido inválido, pedido já foi aceito.'
          })
        }
      )
      .catch((_) => Toast({
          type: 'error',
          title: 'Erro',
          message: 'Ops! ocorreu um erro ao tentar iniciar separação do pedido.'
        })
      )
      .finally(() => dispatch({
          type: 'GET_ORDER',
          data: { ...getState().order.orderPreview, isLoading: false }
        })
      )
  }
}

//** PUT soonForDelivery */
export const soonForDelivery = (orderId, statusId) => {
  return (dispatch, getState) => {
    dispatch({
      type: 'GET_ORDER',
      data: { ...getState().order.orderPreview, isLoading: true }
    })

    return axios
      .put(`${BASE_URL}/pedido/${orderId}/pronto-entrega`)
      .then((_) => {
        dispatch({
          type: 'GET_ORDER',
          data: { ...getState().order.orderPreview, id_status: statusId }
        })

        _handleUpdateState(dispatch, getState)
      })
      .catch((_) => Toast({
          type: 'error',
          title: 'Erro',
          message: 'Ops! ocorreu um erro ao atualizar pedido.'
        })
      )
      .finally(() => dispatch({
          type: 'GET_ORDER',
          data: { ...getState().order.orderPreview, isLoading: false }
        })
      )
  }
}

//** PUT onTheMove */
export const onTheMove = (orderId, username, statusId) => {
  return (dispatch, getState) => {
    dispatch({
      type: 'GET_ORDER',
      data: { ...getState().order.orderPreview, isLoading: true }
    })

    return axios
      .get(`${BASE_URL}/pedido/${orderId}/comanda?operador=${username}`, {
        responseType: 'blob'
      })
      .then((res) => {
        const blob = new Blob([res.data], { type: 'application/pdf' })

        if (window.navigator && window.navigator.msSaveOrOpenBlob) {
          window.navigator.msSaveOrOpenBlob(blob)
        }

        const blobURL = URL.createObjectURL(blob)
        window.open(blobURL)

        if (
          getState().order.orderPreview.id_status !== 4 &&
          getState().order.orderPreview.id_status !== 5
        ) {
          dispatch({
            type: 'GET_ORDER',
            data: { ...getState().order.orderPreview, id_status: statusId }
          })

          _handleUpdateState(dispatch, getState)
        }
      })
      .catch((_) => Toast({
          type: 'error',
          title: 'Erro',
          message: 'Ops! ocorreu um erro ao tentar gerar comanda.'
        })
      )
      .finally(() => dispatch({
          type: 'GET_ORDER',
          data: { ...getState().order.orderPreview, isLoading: false }
        })
      )
  }
}

//** PUT onTwoAway */
export const onTwoAway = (orderId, statusId) => {
  return (dispatch, getState) => {
    dispatch({
      type: 'GET_ORDER',
      data: { ...getState().order.orderPreview, isLoading: true }
    })

    return axios
      .put(`${BASE_URL}/pedido/${orderId}/encaminhar`)
      .then((_) => {
        dispatch({
          type: 'GET_ORDER',
          data: { ...getState().order.orderPreview, id_status: statusId }
        })

        _handleUpdateState(dispatch, getState)
      })
      .catch((_) => Toast({
          type: 'error',
          title: 'Erro',
          message: 'Ops! ocorreu um erro ao atualizar pedido.'
        })
      )
      .finally(() => dispatch({
          type: 'GET_ORDER',
          data: { ...getState().order.orderPreview, isLoading: false }
        })
      )
  }
}

//** PUT canceled */
export const canceled = (orderId, statusId = 5) => {
  return (dispatch, getState) => {
    if (getState().order.orderPreview) {
      dispatch({
        type: 'GET_ORDER',
        data: { ...getState().order.orderPreview, isLoading: true }
      })
    }

    // Options Radios
    const inputOptions = new Promise((resolve) => {
      resolve({
        'Loja fechada': 'Loja fechada',
        'Alta demanda na cozinha': 'Alta demanda na cozinha',
        'Loja não atendeu o pedido': 'Loja não atendeu o pedido',
        'Fora do raio de entrega': 'Fora do raio de entrega',
        'Não tenho motoboy': 'Não tenho motoboy',
        'Motoboy ausente na plataforma': 'Motoboy ausente na plataforma',
        'Sem produtos para o pedido': 'Sem produtos para o pedido',
        'Motoboy retornou para a loja': 'Motoboy retornou para a loja',
        'Cliente final não atendeu motoboy': 'Cliente final não atendeu motoboy',
        'Motoboy não compareceu': 'Motoboy não compareceu'
      })
    })

    MySwal.fire({
      title: 'Tem certeza?',
      text: 'Depois de cancelado esse pedido não poderá ser mais reaberto.',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Sim, confirmar',
      cancelButtonText: 'Fechar',
      buttonsStyling: false,
      //input
      input: 'radio',
      inputOptions,
      //Estilo CSS
      customClass: {
        confirmButton: 'btn btn-success',
        cancelButton: 'btn btn-outline-danger ml-1',
        input: 'd-flex align-items-start flex-column bd-highlight' //bg-light-danger
      },
      inputValidator: (value) => {
        if (!value) {
          return 'Por favor informe o motivo do cancelamento ):'
        }
      }
    }).then(function (result) {
      if (result.value) {
        axios
          .put(`${BASE_URL}/pedido/${orderId}/cancelar?descricao=${result.value}`)
          .then(
            (_) => {
              MySwal.fire({
                icon: 'success',
                title: 'Cancelado!',
                text: 'Pedido cancelado com sucesso.',
                customClass: {
                  confirmButton: 'btn btn-success'
                }
              })

              if (getState().order.orderPreview) {
                dispatch({
                  type: 'GET_ORDER',
                  data: {
                    ...getState().order.orderPreview,
                    id_status: statusId
                  }
                })
              }

              _handleUpdateState(dispatch, getState)
            },
            (_) => {
              MySwal.fire({
                title: 'Error!',
                text: 'Ops! Esse pedido já foi cancelado.',
                icon: 'error',
                customClass: {
                  confirmButton: 'btn btn-primary'
                },
                buttonsStyling: false
              })
            }
          )
          .catch((_) => {
            MySwal.fire({
              title: 'Error!',
              text: 'Ops! Ocorreu um erro ao tentar cancelar pedido.',
              icon: 'error',
              customClass: {
                confirmButton: 'btn btn-primary'
              },
              buttonsStyling: false
            })
          })
          .finally(() => {
            if (getState().order.orderPreview) {
              dispatch({
                type: 'GET_ORDER',
                data: { ...getState().order.orderPreview, isLoading: false }
              })
            }
          })
      }

      if (getState().order.orderPreview) {
        dispatch({
          type: 'GET_ORDER',
          data: { ...getState().order.orderPreview, isLoading: false }
        })
      }
    })
  }
}

//** PUT delivered */
export const delivered = (orderId, statusId) => {
  return (dispatch, getState) => {
    dispatch({
      type: 'GET_ORDER',
      data: { ...getState().order.orderPreview, isLoading: true }
    })

    return axios
      .put(`${BASE_URL}/pedido/${orderId}/entregar`)
      .then((_) => {
        dispatch({
          type: 'GET_ORDER',
          data: { ...getState().order.orderPreview, id_status: statusId }
        })

        _handleUpdateState(dispatch, getState)
      })
      .catch((_) => Toast({
          type: 'error',
          title: 'Erro',
          message: 'Ops! ocorreu um erro ao atualizar pedido.'
        })
      )
      .finally(() => dispatch({
          type: 'GET_ORDER',
          data: { ...getState().order.orderPreview, isLoading: false }
        })
      )
  }
}

export const removeProductOrder = (orderId, item) => {
  return (dispatch, getState) => {
    MySwal.fire({
      title: 'Remover este produto?',
      text: 'Atenção: é sempre importante manter a transparência com o cliente, para qualquer alteração do pedido entre em contato e informe o motivo da alteração.',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Estou ciente!',
      cancelButtonText: 'Cancelar',
      customClass: {
        confirmButton: 'btn btn-success',
        cancelButton: 'btn btn-outline-danger ml-1'
      },
      buttonsStyling: false
    }).then(function (result) {
      if (result.value) {
        const store = getState().order.orderPreview
        const user = getUserData()
        axios
          .post(`${BASE_URL}/pedido/remover-item`, {
            id: item.id,
            id_pedido: orderId,
            quantidade: item.quantidade
          })
          .then((_) => {
            MySwal.fire({
              icon: 'success',
              title: 'Remoção de Produto',
              text: 'Produto removido com sucesso.',
              customClass: {
                confirmButton: 'btn btn-success'
              }
            })

            dispatch({ type: 'REMOVE_PRODUCT'})
            dispatch(getOrder(orderId))
            dispatch(onTheMove(store.id, user.nome, 6))
          })
          .catch((err) => {
            if (err.response.data) {
              MySwal.fire({
                title: 'Error!',
                text: err.response.data.mensagem,
                icon: 'error',
                customClass: {
                  confirmButton: 'btn btn-primary'
                },
                buttonsStyling: false
              })
            } else {
              MySwal.fire({
                title: 'Error!',
                text: 'Ops! Ocorreu um erro ao tentar remover produto',
                icon: 'error',
                customClass: {
                  confirmButton: 'btn btn-primary'
                },
                buttonsStyling: false
              })
            }
          })
      }
    })
  }
}