// ** Logo
import logo from '@src/assets/images/logo/logo.png'

const LogoSpinnerComponent = () => {
  return (
    <div className="vh-100">
      <img className="fallback-logo img-fluid" src={logo} alt="logo" />
    </div>
  )
}

export default LogoSpinnerComponent
