import React, { createContext, useContext, useEffect } from 'react'

import { useDispatch } from 'react-redux'
import { addOrder, addOrderSupplies } from '@src/redux/actions/notification'

import { getToken } from '@src/auth/utils'
import { io } from 'socket.io-client'

const [HTTP, _, HOST, PATH] = process.env.REACT_APP_API_URL_NOTIFICATION.split('/')

const socket = io(`${HTTP}//${HOST}`, {
  path: PATH !== undefined ? `/${PATH}/socket.io` : '/socket.io',
  autoConnect: getToken() ?? false,
  auth: { token: getToken() }
})

const Notification = createContext({})

export const NotificationContext = ({ children }) => {
  const dispatch = useDispatch()

  const connect = () => socket.connect()
  const disconnect = () => socket.disconnect()

  useEffect(() => {
    socket.on('NEW_ORDER', (data) => {
      dispatch(addOrder(data))
    })

    socket.on('NEW_ORDER_SUPPLIES', (data) => {
      dispatch(addOrderSupplies(data))
    })
  }, [])

  return <Notification.Provider value={{ connect, disconnect }}>{children}</Notification.Provider>
}

export function useNotification() {
  return useContext(Notification)
}
