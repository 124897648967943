const initialState = {
  orders: [],
  orderPreview: null,
  totalPage: 0,
  page: 1,
  more_page: false,
  params: {},
  type_order: "immediate"
}

const orderReducer = (state = initialState, action) => {
  switch (action.type) {
    case "GET_ORDERS":
      return {
        ...state,
        orders: action.data.pedidos,
        totalPage: action.data.total,
        page: action.data.pagina,
        more_page: action.data.mais_paginas,
        params: action.params,
        type_order: action.type_order
      }

    case "GET_ORDER":
      return { ...state, orderPreview: action.data }

    case "GET_ALL_ORDERS":
      return { ...state }

    case "PUT_ACCEPT_ORDER":
      return { ...state }

    case "ADD_PRODUCT":
      return { ...state }

    case "UPDATE_PRODUCT":
      return { ...state }

    case "REMOVE_PRODUCT":
      return { ...state }

    default:
      return state
  }
}

export default orderReducer
