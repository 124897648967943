import soundAlert from "@src/assets/sound/notification.wav"
import { maskBRL } from '@utils'
import 'moment/locale/pt-br'
import React from "react"
import {
  Button, Col, Label, Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Row
} from "reactstrap"
import useSound from "use-sound"
import moment from "moment"

const NotificationNewOrdemSupplies = props => {
  const { dispatch, remove, data } = props

  const [play, { stop }] = useSound(soundAlert, {
    volume: 0.95,
    interrupt: false
  })

  const [seconds, setSeconds] = React.useState(60)

  function onClosed() {
    stop()
    remove()
  }

  React.useMemo(() => {
    function initCoutDown() {
      if (seconds > 0) {
        setTimeout(() => setSeconds(seconds - 1), 1000)
        play()
      }
    }

    initCoutDown()

    if (seconds === 0) onClosed()
    
  }, [seconds])

  const renderModal = () => (
    <div className="theme-modal-success">
      <Modal
        isOpen={true}
        toggle={remove}
        className="modal-dialog-centered modal-lg"
        modalClassName="modal-success"
      >
        <ModalHeader toggle={remove}> Novo Pedido - Loja de Insumos </ModalHeader>
        <ModalBody>
          <Row> 
            <Col md={4}>
              <Label for="amount"> <strong> Valor Total </strong> </Label>
              <h1> {maskBRL(data.amount)} </h1>
            </Col>
            <Col>
              <Label for="amount"> <strong> Data </strong> </Label>
              <h1> {moment(data.created_at).format('LLL')} </h1>
            </Col>
          </Row>
        </ModalBody>
        <ModalFooter>
          <Button color="secondary" onClick={onClosed}> Fechar </Button>
        </ModalFooter>
      </Modal>
    </div>
  )

  return <div className="demo-inline-spacing">{renderModal()}</div>
}

export default NotificationNewOrdemSupplies
