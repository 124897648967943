// ** Initial State
const initialState = {
  query: ''
}

const navbarReducer = (state = initialState, action) => {
  switch (action.type) {
    case 'HANDLE_SEARCH_QUERY':
      return { ...state, query: action.val }
    default:
      return state
  }
}

export default navbarReducer
