import NotificationIcon from "@src/assets/images/icons/notification.gif"
import soundAlert from "@src/assets/sound/notification.wav"
import { acceptOrder } from "@src/views/Pedido/store/actions"
import moment from "moment"
import React from "react"
import {
  Button, Col, FormGroup,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Row
} from "reactstrap"
import useSound from "use-sound"

import 'moment/locale/pt-br'

const NotificationNewOrdem = props => {
  const { dispatch, remove, data } = props

  const [play, { stop }] = useSound(soundAlert, {
    volume: 0.95,
    interrupt: false
  })

  const [seconds, setSeconds] = React.useState(60)

  function handleAcceptOrder() {
    try {
      dispatch(acceptOrder(data.id))
      remove()
    } catch (error) {
      console.error(error)
    } finally {
      stop()
    }
  }

  React.useMemo(() => {
    function initCoutDown() {
      if (seconds > 0) {
        setTimeout(() => setSeconds(seconds - 1), 1000)
        play()
      }
    }

    initCoutDown()

    if (seconds === 0) handleAcceptOrder()
    
  }, [seconds])

  const renderModal = () => (
    <div className="theme-modal-success">
      <Modal
        isOpen={true}
        toggle={remove}
        className="modal-dialog-centered modal-lg"
        modalClassName="modal-success"
      >
        <ModalHeader toggle={remove}>
          {data.id_agendamento ? "Novo agendamento" : "Novo pedido"}
        </ModalHeader>
        <ModalBody>
          <Row>
            <Col>
              <div>
                <FormGroup className="d-flex justify-content-center align-items-center">
                  <div>
                    Você tem um novo
                    <h2 className="display-4">
                      {data.id_agendamento ? "agendamento" : "pedido"}
                    </h2>
                    {data.id_agendamento && `para ${moment(data.id_agendamento).format("LLL")}`}
                  </div>
                  <img src={NotificationIcon} alt="new order" width="100" />
                </FormGroup>
              </div>
            </Col>
          </Row>

        </ModalBody>
        <ModalFooter>
          <Button color="success" onClick={handleAcceptOrder}>
            Aceitando em {`${seconds}s`}
          </Button>
        </ModalFooter>
      </Modal>
    </div>
  )

  return <div className="demo-inline-spacing">{renderModal()}</div>
}

export default NotificationNewOrdem
