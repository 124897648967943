// ** Add new order to list notifications
export const addOrder = order => {
  return dispatch => {
    dispatch({ type: 'ADD_NOTIFICATION_ORDER', order})
  }
}

// ** Add new order to list notifications
export const removeOrder = order => {
  return dispatch => {
    dispatch({ type: 'REMOVE_NOTIFICATION_ORDER', order})
  }
}


export const addOrderSupplies = orderSupplie => {
  return dispatch => {
    dispatch({ type: 'ADD_NOTIFICATION_ORDER_OF_SUPPLIES', data: orderSupplie})
  }
}

export const removeOrderSupplies = orderSupplie => {
  return dispatch => {
    dispatch({ type: 'REMOVE_NOTIFICATION_ORDER_OF_SUPPLIES', data: orderSupplie})
  }
}