import Avatar from '@components/avatar'
import React, { Fragment } from 'react'
import { X, Check, Bell, AlertTriangle } from 'react-feather'
import { toast } from 'react-toastify'
import moment from 'moment'

moment.locale('pt-br')

const ToastComponent = ({ title, message, color, icon }) => (
  <Fragment>
    <div className="toastify-header">
      <div className="title-wrapper">
        <Avatar size="sm" color={color} icon={icon} />
        <h6 className="toast-title">{title}</h6>
      </div>
      <small className="text-muted">{moment().format('ll')}</small>
    </div>
    <div className="toastify-body">
      <span role="img" aria-label="toast-text">
        {message}
      </span>
    </div>
  </Fragment>
)

const Toast = (props) => {
  switch (props.type) {
    case 'success':
      toast.success(<ToastComponent color="success" icon={<Check size={12} />} {...props} />, {
        hideProgressBar: true
      })
      break
    case 'error':
      toast.error(<ToastComponent color="danger" icon={<X size={12} />} {...props} />, {
        hideProgressBar: true
      })
      break
    case 'info':
      toast.info(<ToastComponent color="info" icon={<Bell size={12} />} {...props} />, {
        hideProgressBar: true
      })
      break
    case 'warning':
      toast.warning(
        <ToastComponent color="warning" icon={<AlertTriangle size={12} />} {...props} />,
        { hideProgressBar: true }
      )
      break

    default:
      toast.success(<ToastComponent color="success" icon={<Check size={12} />} {...props} />, {
        hideProgressBar: true
      })
      break
  }
}

export default Toast
