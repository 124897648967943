export const isObjEmpty = (obj) => Object.keys(obj).length === 0

export const kFormatter = (num) => (num > 999 ? `${(num / 1000).toFixed(1)}k` : num)

export const htmlToString = (html) => html.replace(/<\/?[^>]+(>|$)/g, '')

const isToday = (date) => {
  const today = new Date()
  return (
    /* eslint-disable operator-linebreak */
    date.getDate() === today.getDate() &&
    date.getMonth() === today.getMonth() &&
    date.getFullYear() === today.getFullYear()
    /* eslint-enable */
  )
}

export const formatDate = (
  value,
  formatting = { month: 'short', day: 'numeric', year: 'numeric' }
) => {
  if (!value) return value
  return new Intl.DateTimeFormat('en-US', formatting).format(new Date(value))
}

export const getTimezoneName = () => {
  return Intl.DateTimeFormat().resolvedOptions().timeZone
}

export const formatDateToMonthShort = (value, toTimeForCurrentDay = true) => {
  const date = new Date(value)
  let formatting = { month: 'short', day: 'numeric' }

  if (toTimeForCurrentDay && isToday(date)) {
    formatting = { hour: 'numeric', minute: 'numeric' }
  }

  return new Intl.DateTimeFormat('en-US', formatting).format(new Date(value))
}

export const isUserLoggedIn = () => localStorage.getItem('ADMIN:userData')
export const getUserData = () => JSON.parse(localStorage.getItem('ADMIN:userData'))

export const getHomeRouteForLoggedInUser = (userRole) => {
  const mainPermission = ['administrador']
  const otherRolesPermission = ['suporte', 'atendimento', 'financeiro', 'comercial']

  if (mainPermission.includes(userRole)) {
    return '/'
  } else if (otherRolesPermission.includes(userRole)) {
    return '/pedidos'
  } else {
    return '/login'
  }
}

export const setPermissionsToProfile = (profile) => {
  if (profile.descricao.toLowerCase() === 'administrador') {
    return [
      {
        action: 'manage',
        subject: 'all'
      }
    ]
  }

  const items = profile.roles.map((x) => {
    switch (x.method) {
      case 'GET':
        x.method = 'read'
        break

      case 'POST':
        x.method = 'create'
        break

      case 'PUT':
        x.method = 'update'
        break

      case 'DELETE':
        x.method = 'delete'
        break

      default:
        x.method.toLowerCase()
    }

    return {
      action: x.method,
      subject: x.path
    }
  })

  return [
    {
      action: 'read',
      subject: '/home'
    },
    {
      action: 'read',
      subject: '/account-settings'
    },
    ...items
  ]
}

export const onlyNumber = (value) => parseInt(value.toString().replace(/[^0-9]/g, ''))

export const maskBRL = (value) => {
  return Intl.NumberFormat('pt-br', {
    style: 'currency',
    currency: 'BRL'
  }).format(value / 100)
}

export const selectThemeColors = (theme) => ({
  ...theme,
  colors: {
    ...theme.colors,
    primary25: '#7367f01a', // for option hover bg-color
    primary: '#D91604', // for selected option bg-color
    neutral10: '#D91604', // for tags bg-color
    neutral20: '#ededed', // for input border-color
    neutral30: '#ededed' // for input hover border-color
  }
})

export const combineComponents = (...components) => {
  return components.reduce(
    (AccumulatedComponents, CurrentComponent) => {
      return ({ children }) => {
        return (
          <AccumulatedComponents>
            <CurrentComponent>{children}</CurrentComponent>
          </AccumulatedComponents>
        )
      }
    },
    ({ children }) => <>{children}</>
  )
}

export const totalPage = (total, items) => {
  return Math.ceil(total / items) || 1
}

export const handleStatusDelivery = (originStatus) => {
  switch (originStatus) {
    case 'SEARCHING':
      return 'BUSCANDO: Foi feita uma solicitação de entrega, mas um entregador ainda não foi encontrado.'

    case 'GOING_TO_ORIGIN':
      return 'A CAMINHO: Um entregador aceitou o pedido e está indo para a origem.'

    case 'ARRIVED_AT_ORIGIN':
      return 'NA LOJA: Um entregador aceitou o pedido e chegou à origem.'

    case 'GOING_TO_DESTINATION':
      return 'INDO PARA O DESTINO: O entregador chegou a origem e está indo para o destino.'

    case 'ARRIVED_AT_DESTINATION':
      return 'CHEGOU AO DESTINO: O entregador chegou ao destino.'

    case 'RETURNING':
      return 'RETORNANDO: O entregador chegou ao destino e está voltando à origem.'

    case 'COMPLETED':
      return 'COMPLETO: A entrega solicitada foi concluída.'

    case 'CANCELED':
      return 'CANCELADO: A entrega solicitada foi cancelada.'

    case 'SCHEDULED':
      return 'AGENDADO: A entrega foi agendada pelo sistema.'

    default:
      return 'BUSCANDO: Foi feita uma solicitação de entrega, mas um entregador ainda não foi encontrado.'
  }
}

export const handleChannel = (channel) => {
  switch (channel) {
    case 'payment_app':
      return 'Pagamento App'

    case 'payment_delivery':
      return 'Pagamento Entrega'

    default:
      return 'Outro'
  }
}

// ** List status change project
export const statusOptions = [
  {
    value: 1,
    name: 'initiate',
    label: 'Iniciado'
  },
  {
    value: 2,
    name: 'accept',
    label: 'Em separação'
  },
  {
    value: 3,
    name: 'onTwoAway',
    label: 'A caminho'
  },
  {
    value: 4,
    name: 'delivered',
    label: 'Entregue'
  },
  {
    value: 5,
    name: 'canceled',
    label: 'Cancelado'
  },
  {
    value: 6,
    name: 'onTheMove',
    label: 'Em movimento'
  },
  {
    value: 7,
    name: 'soonForDelivery',
    label: 'Pronto entrega'
  }
]
